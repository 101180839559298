import React from 'react'
import { Cookies } from 'react-cookie-consent'
import {Helmet} from 'react-helmet'

function GoogleTagManager(){
    return(
        <div>
            <Helmet>
            <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-162106213-1"></script>
            <script>
            {`
                    window.agreed=function(){
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                    gtag('set', 'allow_google_signals', true );
                gtag('config', 'UA-162106213-1', { 'anonymize_ip': false , 'allow_google_signals': true });
                    }
            `}
            </script>
            </Helmet>
            {Cookies.get('offshore-consent')
            ? 	<Helmet>
            <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-162106213-1"></script>
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                    gtag('set', 'allow_google_signals', true );
                gtag('config', 'UA-162106213-1', { 'anonymize_ip': false, 'allow_google_signals': true  });
                    gtag('config', 'UA-162106213-2', { 'anonymize_ip': true, 'allow_google_signals': false });
            `}
                    </script>
                    </Helmet>
                    :
            <Helmet>
            <script defer src="https://www.googletagmanager.com/gtag/js?id=UA-162106213-2"></script>
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-162106213-2', { 'anonymize_ip': true, 'allow_google_signals': false });
            `}
            </script>
            </Helmet>
            }
        </div>
    )
}

export default GoogleTagManager