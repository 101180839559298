import React from 'react'
import style from './headerTemplate.module.scss'

function HeaderTemplate({leftSideContent, rightSideContent}){

    const leftSideClass = rightSideContent ? style.leftSide : style.leftSide + ' ' + style.fullWidth

    return(
        <div className={style.headerContainer}>
            <div className={leftSideClass}>
                {leftSideContent}       
            </div>
            <div className={style.rightSide}>
                {rightSideContent}
            </div>
        </div>
    )
}

export default HeaderTemplate