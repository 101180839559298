import React, {useState} from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import style from './contactForm.module.scss'
import ReCAPTCHA from "react-google-recaptcha";
import DateTimePicker from 'react-datetime-picker';

import ErrorFocus from './ErrorFocus'
import ServiceData from 'components/services/ServiceData'
import {LoadingMessage, SuccessMessage, FailureMessage} from './ContactMessages'
import { Link } from 'react-router-dom';

const recaptchaRef = React.createRef(); // Moving this into the function breaks the form (somehow the Recaptcha element can't set the ref)
function ContactFrom(){
    let requestMessageConfirmation = null;

    const [callDate, setCallDate] = useState(new Date());
    const [request, setRequest] = useState({
        sent: false,
        loading: false,
        success: false,
    })

    function validation(values)
    {
        const errors = {};
        if (!values.name){
            errors.name = 'Name field cannot be empty';
        }

        if (!values.email) {
            errors.email = 'Email field cannot be empty';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }

        if (!values.description){
            errors.description = 'Description field cannot be empty';
        }

        if (values.services.length === 0){
            errors.services = 'Please select at least 1 service';
        }

        if (!values.acceptPrivacyPolicy){
            errors.acceptPrivacyPolicy = 'You must accept the Privacy Policy';
        }

        return errors;
    }

    // Choose which status message should be displayed
    if (request.sent)
    {
        if (request.loading)
        {
            requestMessageConfirmation = <LoadingMessage />;
        }else if (request.success)
        {
            requestMessageConfirmation = <SuccessMessage />;
        }else if (!request.success)
        {
            requestMessageConfirmation = <FailureMessage />;
        }
    }

    async function mailerRequest(values, resetForm){
        const API_PATH = 'https://offshorelab.com/mailer/mail.php'; // Production
        //const API_PATH = 'https://offshorebankaccounts.com/mailer/mail_benas.php'; // Testing

        setRequest({
            loading: true,
            sent: true,
            success: false
        })

        const token = await recaptchaRef.current.executeAsync(); // Comment when testing

        if (token){ // Comment when testing
            // HTTP POST
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: { 'content-type': 'application/json' },
                data:
                {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    subject: values.subject,
                    description: values.description,
                    services: values.services,
                    scheduleCall: values.scheduleCall,
                    callDate: callDate.getDate() + "/" + (callDate.getMonth()+1) + "/" + callDate.getFullYear() + " " + callDate.getHours() + ":" + callDate.getMinutes(),
                    token: token // Comment when testing
                }
            })
            .then(response => {
                setRequest({
                    sent: true,
                    loading: false,
                    success: response.data.sent,
                })

                resetForm()
            })
            .catch(() =>
            {
                setRequest({
                    sent: true,
                    loading: false,
                    success: false,
                })
            });
        }else{
            setRequest({
                sent: true,
                loading: false,
                success: false,
            })
        }
    }

    return(
        <div>
            <Formik
                initialValues={{ name: '', email: '', phone: '', subject:'', description:'', services:[], scheduleCall: false, acceptPrivacyPolicy: false }}

                validateOnChange={true}
                validateOnBlur={false}
                validate={values => validation(values)}

                onSubmit={async (values, onSubmitProps) => {
                    await mailerRequest(values, onSubmitProps.resetForm);
                    onSubmitProps.setSubmitting(false);

                    // Scroll down to show the request confirmation message
                    let top = document.getElementById("submitButton").documentOffsetTop() - ( window.innerHeight / 2 );
                    window.scrollTo( 0, top+200 );
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur
                }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <input
                            type="text"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            spellCheck="false"
                            className={errors.name && touched.name ? style.invalidField : style.field }
                            placeholder="Your Name *"
                        />
                        <div className={style.invalidText}>
                            {errors.name && touched.name && errors.name}
                        </div>

                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            spellCheck="false"
                            className={errors.email && touched.email ? style.invalidField : style.field }
                            placeholder="Your Email *"
                        />
                        <div className={style.invalidText}>
                            {errors.email && touched.email && errors.email}
                        </div>

                        <input
                            type="tel"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone}
                            spellCheck="false"
                            className={style.field}
                            placeholder="Your Phone Number"
                        />

                        <input
                            type="text"
                            name="subject"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.subject}
                            spellCheck="false"
                            className={style.field}
                            placeholder="Subject"
                        />

                        <textarea
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                            spellCheck="false"
                            className={errors.description && touched.description ? style.invalidTextAreaField : style.textAreaField }
                            placeholder="How Can We Help? *"
                        />
                        <div className={style.invalidText}>
                            {errors.description && touched.description && errors.description}
                        </div>

                        <label className={style.checkmarkLabel}>
                            <input
                                type="checkbox"
                                name="scheduleCall"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.scheduleCall}
                                checked={values.scheduleCall}
                                className={style.defaultCheckmark}
                            />
                            <span className={style.checkmark}></span>
                            I would like to schedule a call
                        </label>

                        {values.scheduleCall ?
                            <div>
                                <DateTimePicker
                                    value={callDate}
                                    onChange={setCallDate}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    disableClock={true}
                                    minDate={new Date()}
                                    className={style.datePicker}
                                />
                                <div className={style.invalidText}>
                                    Available times (08:00 - 22:00 GMT+1 MON-SUN)
                                </div>
                            </div>
                        : null
                        }

                        <div className={style.checkmarkGroup}>
                            <div
                                className={style.label}
                            >
                                Services you are interested in *
                            </div>
                            <div className={style.invalidText}>
                                {errors.services && touched.services && errors.services}
                            </div>

                            { // Generate checkmarks for services
                                ServiceData.map((service, key) =>{
                                    return (
                                        <label className={style.checkmarkLabel} key={key}>
                                            <input
                                                type="checkbox"
                                                name="services"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={service.title}
                                                checked={values.services.includes(service.title)}
                                                className={style.defaultCheckmark}
                                            />
                                            <span className={style.checkmark}></span>
                                            {service.title}
                                        </label>
                                    )
                                })
                            }
                        </div>

                        <label className={style.checkmarkLabel}>
                            <input
                                type="checkbox"
                                name="acceptPrivacyPolicy"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.acceptPrivacyPolicy}
                                checked={values.acceptPrivacyPolicy}
                                className={style.defaultCheckmark}
                            />
                            <span className={style.checkmark}></span>
                            I have read and accept <Link to={'/privacy'}>Privacy Policy</Link> *
                        </label>
                        <div className={style.invalidText}>
                            {errors.acceptPrivacyPolicy && touched.acceptPrivacyPolicy && errors.acceptPrivacyPolicy}
                        </div>

                        <div className={style.smallText}>
                            All fields marked with an asterisk (*) must be filled.
                        </div>

                        <button type="submit" id="submitButton" className={style.submitButton + " simpleButton"}>
                            Submit
                        </button>

                        {requestMessageConfirmation}

                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LdAYS0dAAAAAEfBw8L7roKYcGAnurPLw3rovXTA"
                            badge = {"bottomleft"}
                        />

                        <ErrorFocus/>
                    </form>
                )}
            </Formik>
        </div>
    )
}

// Used for scrolling on form submit
Element.prototype.documentOffsetTop = function () {
    return this.offsetTop + ( this.offsetParent ? this.offsetParent.documentOffsetTop() : 0 );
};

export default ContactFrom
