const isURL = (str) => {
  const expression = new RegExp(
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  );

  return expression.test(str);
};

const isDomain = (url) => {
  const pattern = new RegExp(
    /(https?:\/\/(.+?\.)?offshorebankaccounts\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/
  );
  return !!pattern.test(url);
};

export { isURL, isDomain }