import React from 'react'
import style from './serviceComponent.module.scss'
import InfoTemplate from 'components/services/serviceTemplate/InfoTemplate'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function BusinessBankAccountOpening({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Bank account for any type of legal entity is a crucial thing. However, today to open a bank account is the hardest part while establishing a new legal entity. It makes it even harder if the owner or director of the company is resident of a different country.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>We open bank accounts for</h2>
			<ul className={style.highlightedList}>
				<li>Digital Businesses</li>
				<li>Holding Companies</li>
				<li>Trusts</li>
				<li>Foundations</li>
				<li>Crypto Businesses</li>
				<li>ICOs</li>
				<li>Forex Brokers</li>
				<li>CFD Brokers</li>
				<li>Casinos</li>
				<li>Gambling Operators</li>
				<li>Scottish Limited Partnerships</li>
				<li>Canada Limited Partnerships</li>
				<li>and other types of legal entities</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Our services include</h2>
			<ul className={style.list}>
				<li>- Analysis of your legal setup and business model</li>
				<li>- Consultation how to improve legal setup if necessary to meet banks expectations</li>
				<li>- Preparation of financial statements if needed</li>
				<li>- Recommendation of best-fit financial institutions for your needs, which are likely to accept you</li>
				<li>- Introduction to the bank or another financial institution</li>
				<li>- Assistance to prepare application and collect supporting documents to open an account</li>
				<li>- Support during on-going communication with a bank after account is open</li>
			</ul>
			<InfoTemplate content={
				<div>Our prices start from 700 EUR plus bank application fees. Additional services are charged extra. For example  consultation of how to improve legal structure starts from 1500 EUR (10 hours of work including analysis of all-important personal circumstances, preparation of memo with structuring alternatives, answering all related questions, conference calls, etc) and preparation of financial statements starts from 500 EUR.</div>
			}/>
		</div>
	)
}

export default BusinessBankAccountOpening