import React from 'react'
import style from './contactHeader.module.scss'
import HeaderTemplate from 'components/templates/HeaderTemplate'

import { ReactComponent as ContactPicture } from 'assets/images/vector/contact.svg';

function ContactHeader() {
	const leftSideContent =
		<h1 className={style.headerTitle}>Contact <div className={style.titleInline}>Us
			<ContactPicture className={style.headerIcon} />
		</div>
		</h1>

	const rightSideContent =
		<div>
			<h2 className={style.littleHeader}>Money never sleeps, neither do we</h2>
			<p className={style.paragraph}>
				As serving clients from all around the globe and partnering with financial institutions in different times zones and being the intermediary between them our working hours never stops. So you are welcome to contact us via livechat on this website, email, telephone, Telegram, Signal or Skype. We will do our best to respond as quick as possible.
			</p>
		</div>

	return <HeaderTemplate leftSideContent={leftSideContent} rightSideContent={rightSideContent} />
}

export default ContactHeader