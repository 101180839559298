import React from 'react'
import style from './contentTemplateMobileBreakpoint.module.scss'

function ContentTemplateMobileBreakpoint({leftSideContent, rightSideContent}){
    return(
        <div>
			<div className={style.mainContainer}>
				<div className={style.left}>
					{leftSideContent}
				</div>

				<div className={style.right}>
					{rightSideContent}
				</div>
			</div>
		</div>
    )
}

export default ContentTemplateMobileBreakpoint