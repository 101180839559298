import React from 'react';
import style from './footer.module.scss';

import { Link, useLocation } from 'react-router-dom';
import LetsTalk from "./LetsTalk";
import SocialMediaLinks from 'components/navigation/socialMediaLinks/SocialMediaLinks';
import { ReactComponent as ObcLogo } from "assets/images/vector/offshorelab-logo.svg";

function Footer() {
	const getInTouchOptOutRoutes = ['/contact']
	const location = useLocation();
	let showGetInTouch = true;

	if (getInTouchOptOutRoutes) {
		getInTouchOptOutRoutes.forEach(route => {
			if (route === location.pathname) {
				showGetInTouch = false;
			}
		});
	}

	return (
		<div>
			{showGetInTouch ?
				<LetsTalk /> : null
			}

			<div className={style.container}>
				<Logo />
				<Menu />
				<div className={style.socialMedia}><SocialMediaLinks /></div>
			</div>
		</div>
	);
}

function Logo() {
	return (
		<div className={style.logo}>
			<Link to="/" onClick={() => ScrollToTop()}>
				<ObcLogo />
			</Link>
		</div>
	);
}

function ScrollToTop() {
	window.scrollTo(0, 0)
}

function Menu() {

	return (
		<div className={style.buttons}>
			<Link to="/privacy">
				<p className={style.button} onClick={() => ScrollToTop()}>
					Privacy Policy
				</p>
			</Link>
			<Link to="/cookies">
				<p className={style.button} onClick={() => ScrollToTop()}>
					Cookies Policy
				</p>
			</Link>
{/*			<Link to="/blog">
				<p className={style.button} onClick={() => ScrollToTop()}>
					Blog
				</p>
			</Link>*/}
			<Link to="/contact">
				<p className={style.button} onClick={() => ScrollToTop()}>
					Contact
				</p>
			</Link>
{/*}			<Link to="/FAQ">
				<p className={style.button} onClick={() => ScrollToTop()}>
					FAQ
				</p>
			</Link>*/}
		</div>
	);
}

export default Footer;
