import React from 'react'
import style from './faqInsideQuestion.module.scss'
import { Link } from 'react-router-dom'
import {MDXProvider} from '@mdx-js/react'

import slugify from 'helpers/Slugify.js'
import MetaTags from 'components/MetaTags'
import FaqStyles from './FaqStyles/FaqStyles'
import FaqInsideTemplate from './FaqInsideTemplate'

function FaqInsideQuestion({category, question, routeList})
{
    const metaData={
        title: question.title + ' | Offshore Bank Accounts FAQ'
    }

    const relatedQuestionCount = 4;
    const leftSideTitle = "Related questions"
    const leftSideContent = 
    category.questions.slice(0, relatedQuestionCount).map((question, key)=>
    <Link to={'/faq/' + slugify(category.title) + '/' + slugify(question.title)} className={style.question} key={key}>
        {question.title}
    </Link>)

    const rightSideContent =
    <MDXProvider components={FaqStyles}>
        {React.createElement(question.mdxComponent)}
    </MDXProvider>

    return(
        <>
        <MetaTags metaData={{...metaData, ...question.metaData}}/>
        <FaqInsideTemplate leftSideTitle={leftSideTitle} leftSideContent={leftSideContent} rightSideContent={rightSideContent} routeList={routeList} takeTitleFromRouteList={false}/>
        </>
    )
}

export default FaqInsideQuestion