import React, { useState, useEffect } from 'react'
import style from './search.module.scss'
import TinyQueue from 'tinyqueue'
import Highlighter from "react-highlight-words"

import { ReactComponent as Loupe } from 'assets/images/vector/loupe.svg'
import { Link } from 'react-router-dom'

/*  IMPORTANT: contentToSearch should be an array of objects with the following parameters:
    content: string for the content to match/find the keywords to,
    link: string for the link (e.g. "/blog/blog-post-1")
*/

function Search({ title, contentToSearch }) {
    const [dropdownContent, setDropdownContent] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(-1);

    // Called when the user unfocuses the input field
    function handleBlur(e) {
        if (e.relatedTarget?.tagName.toLowerCase() !== 'a') {
            document.getElementById("searchInput").value = ""
            handleChange()
        }
    }

    // Called when the user changes the text in the input field
    function handleChange() {
        let queryString = document.getElementById("searchInput").value

        if (/\S/.test(queryString)) {
            const keywords = queryString.split(" ").filter(word => /\S/.test(word))
            const regexes = keywords.map(word => {
                if (keywords.length == 1)
                    return new RegExp(word, "gi")
                else
                    return new RegExp("\\b" + word + "\\b", "gi")
            })

            // Create a priority queue to find most relevant query answers
            let queue = new TinyQueue([], function (a, b) {
                return b.matches - a.matches
            })

            // Iterate through all content and calculate matches
            for (let i = 0; i < contentToSearch.length; i++) {
                let matching = calculateMatch(regexes, contentToSearch[i].content)
                matching.link = contentToSearch[i].link;
                queue.push(matching)
            }

            // There are matching query answers
            if (queue.peek().matches !== 0) {
                // Generate an array with query answers to display
                let searchResults = [];
                while (queue.length > 0 && queue.peek().matches !== 0) {
                    searchResults.push(queue.pop());
                }

                setDropdownContent(
                    <div className={style.dropdown}>
                        {
                            searchResults.map((match, key) => {
                                return (
                                    <Link to={match.link}
                                        className={style.link}
                                        onMouseOver={() => setHoverIndex(key)}
                                        key={key}
                                        tabIndex="0"
                                    >
                                        <Highlighter
                                            highlightClassName={key === hoverIndex ? style.active : style.highlight}
                                            unhighlightClassName={key === hoverIndex ? style.active : style.unhighlight}
                                            searchWords={regexes}
                                            autoEscape={false}
                                            textToHighlight={match.content}
                                            key={key}
                                        />
                                    </Link>
                                )
                            })
                        }
                    </div>)
            } else { // There are no matching query answers
                setDropdownContent(
                    <div className={style.dropdown}>
                        No results found
                    </div>)
            }
        } else { // Hide the dropdown
            setDropdownContent(null)
            setHoverIndex(-1)
        }
    }

    useEffect(() => {
        handleChange()
        // Removes the warning message about missing dependency in the array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoverIndex])

    function calculateMatch(keywordRegExs, content) {
        let matches = 0;
        keywordRegExs.forEach(regex => {
            if (content.toLowerCase().match(regex)) {
                matches++;
            }
        })

        return { matches: matches, content: content };
    }

    return (
        <div className={style.mainContainer}>
            <div className={style.title}>
                {title}
            </div>

            <div className={style.form}
                onChange={() => handleChange()}
                onBlur={(e) => handleBlur(e)}
            >
                <input
                    id="searchInput"
                    type="text"
                    placeholder="Enter keyword here"
                    name="keywords"
                    spellCheck="false"
                    autoComplete="off"
                    className={style.input}
                />
                <Loupe className={style.icon} />
                {dropdownContent}
            </div>
        </div>
    )
}

export default Search