import React, { useState } from 'react';
import { isURL } from './validation';

const LazyImage = (props) => {
  const { src, alt, style, placeHolderStyle, className } = props;

  const [imageSrc, setImageSrc] = useState(undefined);

  if (imageSrc === undefined) {
    if (isURL(src)) {
      setImageSrc(src);
    } else {
      import(`assets/images/raster/blog/${src}`).then((s) => {
        setImageSrc(s.default);
      });
    }
  }

  if (!imageSrc && placeHolderStyle) {
    return <div style={{ ...placeHolderStyle, backgroundColor: 'transparent' }} />
  }
  else {
    return <img src={imageSrc} alt={alt} style={style} className={className} />;
  }
};

export default LazyImage;
