import React from 'react';
import style from './serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function CompanyRegistration({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Together with our partners, we assist our clients in choosing a suitable form and a jurisdiction of legal entity regarding their needs.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}
function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Types of legal entities</h2>
			<ul className={style.highlightedList}>
				<li>Offshore Companies</li>
				<li>Limited Companies</li>
				<li>Holding Companies</li>
				<li>Trusts</li>
				<li>Foundations</li>
				<li>Limited Partnerships</li>
				<li>and other types of legal entities</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Our services include</h2>
			<ul className={style.list}>
				<li>- Analysis of your current legal setup, business model and of all-important personal circumstances</li>
				<li>- Preparation of memo with structuring alternatives</li>
				<li>- Answering all related questions, conference calls</li>
				<li>- Registration of the legal entity</li>
				<li>- Assistance to open a bank account</li>
			</ul>
		</div>
	)
}

export default CompanyRegistration;
