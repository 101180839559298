import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ScrollToTop from './hooks/ScrollToTop';
import HeaderDesktop from './components/navigation/header/HeaderDesktop';
import HeaderMobile from './components/navigation/header/HeaderMobile';
import Footer from './components/navigation/footer/Footer';
import CookieConsentFooter from './components/legal/CookieConsentFooter';

import LiveChatScript from './components/LiveChatScript';
import GoogleTagManager from './components/legal/GoogleTagManager';
import FacebookPixel from './components/legal/FacebookPixel';
import Routes from './routes.js';
import 'styles/global.scss';

class App extends Component {
	render() {
		return (
			<div>
				<BrowserRouter>
					<div className="App">
						<GoogleTagManager />
						<FacebookPixel />
						<HeaderDesktop />
						<HeaderMobile />
						<ScrollToTop />
						<Routes />
						<Footer />
						<CookieConsentFooter />
						<LiveChatScript />
					</div>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
