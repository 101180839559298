import React from 'react';
import { Link } from "react-router-dom";
import style from './blogOverview.module.scss';
import LazyImage from 'helpers/LazyImage';

import { ReactComponent as Arrow } from 'assets/images/vector/arrow.svg'

// Takes in a BlogContent and displays it's overview
function BlogOverview({ meta, page }) {

	const to = {
		pathname: "/blog/" + meta.slug,
		page: page
	}

	return (
		<Link to={to} className={style.link}>
			<div className={style.hover}>
				<div className={style.blogPostContainer}>
					<div className={style.image}>
						<LazyImage src={`blog${meta.id}-small.jpg`} alt={meta.imageAlt} placeHolderStyle={{}} key={meta.id} />
					</div>
					<h3 className={style.littleHeader}>{meta.headline}</h3>
					<p className={style.paragraph}>
						{meta.description}
					</p>

					<div className={style.readMore}>
						Read more
						<Arrow className={style.readMoreArrow} />
					</div>
				</div>
			</div>
		</Link>
	);
}
export default BlogOverview;
