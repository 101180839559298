import React from 'react';
import {Link} from "react-router-dom";
import style from './serviceComponent.module.scss';
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function SecondCitizenship({data}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Obtaining second citizenship has never been easier than it is currently with the citizenship by investment programs.
		</p>
		<p className={style.paragraph}>
			If you are looking to obtain a second home in another country, you should associate with experienced agencies and advisors to suit your objectives.
		</p>
	</div>

	return <ServiceTemplate data={data} headerRightContent={headerRightContent} leftContent={<LeftContent data={data}/>} rightContent={<RightContent />}/>
}

function LeftContent({data}){
	return(
		<div>
			<h2 className={style.littleHeader}>Citizenship programs</h2>
			<ul className={style.highlightedList}>
				{
					data.subServices.map((subService, key)=>
						<li key={key}><Link to={subService.link}>{subService.title}</Link></li>
					)
				}
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<p className={style.paragraph}>
				As such, we can help you secure your second citizenship through our services that include;
			</p>
			<ul className={style.indentedList}>
				<li>i. Analysis of your current situation and crucial personal needs.</li>
				<li>ii. Recommendation of suitable citizenship programs.</li>
				<li>iii. Introduction to the relevant authorities.</li>
				<li>iv. Assistance in preparation of the application and required legal documents.</li>
			</ul>
			<p className={style.paragraph}>
				While the path to second citizenship relies on an applicant's individual needs, dual citizenship benefits are undeniably attractive to everyone.
			</p>
			<p className={style.paragraph}>
				The following are the benefits that you can look forward to as a holder of dual citizenship.
			</p>

			<p className={style.paragraphHeader}>
				Visa-free Travel to Key Destinations
			</p>
			<p className={style.paragraph}>
				Having a second passport significantly increases your global mobility as it international travelling and allows access to countries that would not have been accessed with the primary passport visa-free.
			</p>
			<p className={style.paragraph}>
				Depending on the country, you take second citizenship, your freedom of movement without a visa can significantly increase. This can radically improve your travel experience and open up more opportunities for investors.
			</p>

			<p className={style.paragraphHeader}>
				Tax Benefits
			</p>
			<p className={style.paragraph}>
				The tax burden has been on an upward trajectory for most entrepreneurs due to the increased tax liabilities.
			</p>
			<p className={style.paragraph}>
				If you are looking for tax reprieve or opportunities for greater tax efficiency, second citizenship in countries where wealth and income are not taxed will be a solid choice for you.
			</p>
			<p className={style.paragraph}>
				Besides, some countries, more so in the Caribbean, offer significantly lower tax rates than other parts of the world.
			</p>

			<p className={style.paragraphHeader}>
				Business Opportunities
			</p>
			<p className={style.paragraph}>
				Citizenship by investment programs for obtaining second citizenship presents lucrative investment opportunities for immigrants. </p>
			<p className={style.paragraph}>
				Based on your desired location, you may benefit from a diversified and expanded market for your products, depending on the investment and the country you choose.				</p>
			<p className={style.paragraph}>
				Besides, a second passport can guarantee you travel, trade, and residency in countries or cities considered global hubs of business, tourism, or innovation.</p>
			<p className={style.paragraph}>
				As globalization continues to dictate the modern market's nature, obtaining a global presence and access to international markets has increasingly become important for entrepreneurs.
			</p>

			<p className={style.paragraphHeader}>
				Better Living Standards
			</p>
			<p className={style.paragraph}>
				If you are looking to elevate your life quality, obtaining a second citizenship in a high-end, developed global economies could be your best shot.
			</p>
			<p className={style.paragraph}>
				Some countries grant citizens improved living standards, including free top medical care and free top education.
			</p>
			<p className={style.paragraph}>
			Possessing some passports such as passports affiliated to Commonwealth and EU gives you access to more opportunities, and your children will access better quality education as they are qualified to apply for any of the universities across Europe.
			</p>

			<p className={style.paragraphHeader}>
				Generational and Lifestyle Benefits
			</p>
			<p className={style.paragraph}>
				Possessing a second passport and residency in a foreign nation allows you to interact with people from different cultures to learn ways of life.
			</p>
			<p className={style.paragraph}>
				Some destinations like the Caribbean will be a great holiday destination and an ideal home to relax during vacations.
			</p>
			<p className={style.paragraph}>
				Some countries allow for the transfer of second citizenship to descendants, and all your dependents, whether your children or parents, will be granted residency and citizenship along with you.
			</p>
			<p className={style.paragraph}>
				While gaining second citizenship has significantly become easier, it can be a hassle that requires a substantial investment, it can help you attain your life and business goals with the correct guidance. Second citizenship will open up new opportunities for you, your family, and your business.
			</p>
		</div>
	)
}

export default SecondCitizenship;
