import React from 'react'
import style from './headerLinks.module.scss'
import { Link } from 'react-router-dom'

function HeaderLinks({callback}){
    return(
        <>
            <Link to="/" className={style.link} onClick={callback}>
                Home
            </Link>
            <Link to="/#services" className={style.link} onClick={callback}>
                Services
            </Link>
{/*           <Link to="/blog" className={style.link} onClick={callback}>
                Blog
            </Link>*/}
            <Link to="/contact" className={style.link} onClick={callback}>
                Contact
            </Link>
{/*}            <Link to="/faq" className={style.link} onClick={callback}>
                FAQ
            </Link>*/}
        </>
    )
}

export default HeaderLinks
