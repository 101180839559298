import React, { useEffect } from 'react';
import style from './home.module.scss';
import { Link, useLocation } from 'react-router-dom';

import MetaTags from 'components/MetaTags'
import ServiceOverview from "components/services/ServiceOverview";
import BlogCarousel from 'components/blog/BlogCarousel';
import { ReactComponent as BankImage } from 'assets/images/vector/offshorelab.svg';

function Home() {
	const location = useLocation()
	const metaData = {
		ogDescription: "Offshore solutions for businesses and individuals",
		keywords: "offshore bank account legal company help"
	};

	// Used for scrolling to service overview
	useEffect(() => {
		if (location.hash) {
			let elem = document.getElementById(location.hash.slice(1))
			if (elem) {
				elem.scrollIntoView({ behavior: "smooth" })
			}
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
		}
	}, [location,])

	return (
		<div className={style.page}>
			<div className="application">

				<MetaTags metaData={metaData} />

			</div>
			<MainView />

			<div id="services">
				<ServiceOverview />
			</div>

			{/*<h1 className={style.littleHeader}>Blog</h1>
			<BlogCarousel />*/}
		</div>
	);
}

function MainView() {
	return (
		<div className={style.container}>
			<div className={style.bankImage}>
				<BankImage />
			</div>
			<div className={style.text}>
				<h1 className={style.header}>Helping to navigate offshore world</h1>
				<p className={style.paragraph}>
					Succeeding in business these days requires international solutions. We help with incorporation, licensing, payments processing, bank accounts, residency and citizenship solutions for business owners.</p>
				<Link to="/contact"><button className={style.button + " simpleButton"}>Contact us</button></Link>
			</div>
		</div>
	);
}


export default Home;
