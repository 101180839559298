import React from 'react';
import style from './contact.module.scss';
import MetaTags from 'components/MetaTags';

import ContactForm from '../../components/contact/ContactForm';
import ContactMeans from '../../components/contact/ContactMeans';
import ContactHeader from '../../components/contact/ContactHeader';
import StillDeciding from 'components/services/StillDeciding';
import ContentTemplate from 'components/templates/ContentTemplate';

function Contact() {
	const metaData = {
		title: `Contact Us | Offshore Lab`,
		ogDescription: "Helping to navigate offshore world",
	};

	const leftSideContent =
		<div>
			<div className={style.sectionHeader}>Let's Talk</div>
			<ContactMeans />
		</div>

	const rightSideContent =
		<div>
			<div className={style.sectionHeader}>Schedule Your Complimentary Consulation</div>
			<ContactForm />
		</div>

	return (
		<div>
			<MetaTags metaData={metaData} />
			<ContactHeader />

			<ContentTemplate leftSideContent={leftSideContent} rightSideContent={rightSideContent} />

			<StillDeciding />
		</div>
	);
}

export default Contact;
