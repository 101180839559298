import React from 'react';
import style from './legal.module.scss';
import { Link } from 'react-router-dom';
import MetaTags from 'components/MetaTags'
import ContentTemplate from 'components/templates/ContentTemplate'

function PrivacyPolicy() {

	const metaData = {
		title: `Privacy Policy | Offshore Lab`,
		ogDescription: "Helping to navigate offshore world",
	};

	const content=
	<>
		<div className={style.littleHeader}>
		1. Overview
		</div>
		<div className={style.paragraph}>
		This Privacy Policy (the <p className={style.paragraphMedium}>“Privacy Policy”</p>) provides explanations on your personal data processing when you use our website (<Link to="/">https://offshorelab.com/</Link>) (the <p className={style.paragraphMedium}>“Website”</p>) and the services (the <p className={style.paragraphMedium}>“Services”</p>) introduced on the Website.
		</div>
		<div className={style.paragraph}>
		OBA Advisory FZE LLC, license number 3179, registered address at A-5801-49 - Flamingo Villas, Ajman, United Arab Emirates (the <p className={style.paragraphMedium}>“Company“</p> or <p className={style.paragraphMedium}>“we“</p>) is the data controller of your personal data processed for the purposes indicated in this Privacy Policy.
		</div>
		<div className={style.paragraph}>
		Any personal data we gather, use or share about you is processed in accordance with the applicable laws. The Company takes all the necessary measures to protect your personal data against unauthorized or unlawful processing and against accidental loss, destruction, or damage. This includes legal, organizational, technical, and physical security measures.
		</div>
		<div className={style.paragraph}>
		By contacting us via “Contact us” option or by filling the “Contact form” online or by using this Website and the Services other how you confirm you have read, understood and accepted this Privacy Policy.
		</div>

		<div className={style.littleHeader}>
		2. Personal data we collect
		</div>
		<div className={style.paragraph}>
		Personal data is any information that relates to an identified or identifiable living individual. Different pieces of information, which collected together can lead to the identification of a particular person, also constitute personal data.
		</div>
		<div className={style.paragraph}>
		The personal data we may process about you depends on your use of the Website and the type of Services you are willing to get from us. Therefore, the processed personal data categories may include the following:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> Personal information (including, but not limited to, first name, last name, identity document data and its copy, country of residence for tax purpose, taxpayer identification number (TIN), address, phone number, e-mail, picture, video, data about you which may be provided in double-checking systems);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> Information about your occupation and income sources (including, but not limited to, type of occupation, countries related to your occupation);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> Financial information (including, but not limited to, source of your funds, monthly income, accounts in financial institutions);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>D.</p> Information about your desirable Services (including, but not limited to purpose of the Services, your personal needs);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>E.</p> Relationship data (including, but not limited to, information about relation to Politically Exposed Person (“PEP”));
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>F.</p> Communication data (including, but not limited to, correspondence, chats, date, time);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>G.</p> History data (including, but not limited to, your experience using the Website, such as the register of timestamped actions performed by you on the Website);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>H.</p> Information related to your use of the Website (including, but not limited to, browser and device data (IP address(es), time zone, time, data, browser information, screen resolution electronic device‘s operational system information, location data (country (code), city), internet service provider (ISP), language version, etc.) and usage data (time spent on the Website, pages visited, links clicked, etc.)).
		</div>

		<div className={style.littleHeader}>
		3. Ways how we get your personal data
		</div>
		<div className={style.paragraph}>
		The Company collects your personal data directly from you or from the third parties when:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> you contact us;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> you use our Website.
		</div>

		<div className={style.littleHeader}>
		4. Purposes for your personal data processing
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> Support services. For this purpose we process your personal data so that we could provide you the necessary consultation based on your expressed request and / or answer any inquiry you may have;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> Provision of the requested Services. For this purpose we evaluate your personal data so that we could suggest the most suitable type of Services for you and provide the agreed Services in the most efficient and effective manner;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> Proper and secure operation of the Website. For this purpose your personal data is processed in order to ensure smooth and secure operation of the Website.
		</div>
		<div className={style.paragraph}>
		If you do not provide the Company with the personal data necessary in order to achieve the above indicated purposes, the Company may not be able to provide you with the requested support or other Services, as well as, ensure proper and secure operation of the Websites. Therefore, the Company may not be able to keep a contact with you, continue business relationship, provide Services or perform other related functions.
		</div>

		<div className={style.littleHeader}>
		5. Legal basis for personal data processing
		</div>
		<div className={style.paragraph}>
		We process your personal data based on at least one of the following grounds:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> you have given a consent (explicitly (i.e., confirming that you “Agree”) or implicitly (i.e., when you choose to contact us via “Contact us” option or by filling a “Contact form”, such initiative and action from your side is treated as your consent for processing your personal data by the Company for the purpose of provision support services to you));
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> your personal data processing is necessary for the performance of a contract or in order to take steps at the request of you prior to entering into a contract;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> your personal data processing is necessary for compliance with a legal obligation to which the controller is a subject;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>D.</p> your personal data is necessary for the purpose of the legitimate interests pursued by us or the third party.
		</div>

		<div className={style.littleHeader}>
		6. Personal data recipients
		</div>
		<div className={style.paragraph}>
		Taking into account the applicable data protection measures, we may disclose and / or transfer your personal data under initiative of our own or upon respective request to the below indicated personal data recipients which are also obliged to ensure the adequate level of protection for your personal data:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> banks, financial, payment and (or) electronic money institutions;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> auditors, legal and financial consultants;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> public authorities;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>D.</p> information technology infrastructure services providers;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>E.</p> fraud detection services providers;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>F.</p> data processors;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>G.</p> other service providers which services may include, or which are engaged in personal data processing executed by the Company.
		</div>

		<div className={style.paragraph}>
		Personal data may also be provided to other recipients if:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> the Company has to comply with a legal obligation to which it is a subject; or
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> such requested personal data is necessary for the concrete data recipient to carry out a particular inquiry in the general interest; or
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> the data requesting party has a legitimate interest to request for such information; or
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>D.</p> other provisions of the applicable laws are applied.
		</div>

		<div className={style.littleHeader}>
		7. Personal data retention periods
		</div>
		<div className={style.paragraph}>
		We process your personal data for no longer than it is necessary in order to achieve the purposes for which they were collected (Article 4).
		</div>
		<div className={style.paragraph}>
		Business practice, legal obligations applied to the Company and maintenance of Company’s ability to establish, exercise and defend possible legal claims may also be the indicators for determination of data retention periods.
		</div>
		<div className={style.paragraph}>
		Depending on the category of personal data and the purpose it is being processed for, your data retention periods applied within the Company are:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> in order to provide support services to you we may process your personal data throughout the provision of support services and for 2 (two) years after our last actual contact with each other (i.e., our last e-mail to you answering your request). If during our communication you request specific Services and we agree to start working on that, then your personal data collected for the purpose of providing support services will be further processed for the purpose of provision of the requested Services and for the period indicated in Paragraph B below;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> when you request specific Services and we agree to start working on that we process the personal data that you have provided throughout our continues business relationship and for 5 (five) years after our business relationship ends (i.e., the requested Services are deemed to be provided);
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> for the purpose of proper and secure operation of the Website, we process your personal data throughout the term we support the Website.
		</div>

		<div className={style.littleHeader}>
		8. Security measures
		</div>
		<div className={style.paragraph}>
		The Company takes various security ensuring technologies and procedures in order to protect your personal data against unauthorised or unlawful processing, accidental loss, misuse, unauthorized access, illegal usage, destruction, disclosure, damage and etc. This includes legal, organisational, technical, and physical security measures, such as the latest security systems, working only with trustworthy service providers, ability to detect cyber security attacks and other threats to the integrity of the Website. However, no transmission of information via email or other telecommunication channels through the internet could be fully secured. Therefore, you should take due care when you are sharing confidential information via e-mail or other telecommunication channels.
		</div>

		<div className={style.littleHeader}>
		9. Cookies
		</div>
		<div className={style.paragraph}>
		Cookies are small information files that your device (personal computer, telephone, etc.) browser finds on the Website and stores in your device. In order to get to know more about cookies, please read our <Link to="/cookies">Cookie Policy</Link>.
		</div>

		<div className={style.littleHeader}>
		10. Your data protection rights
		</div>
		<div className={style.paragraph}>
		You have certain legal rights in relation to the processing of your personal data:
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>A.</p> Right of access to the information that is processed about you;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>B.</p> Rights to rectification;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>C.</p> Rights to erasure;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>D.</p> Right to restriction of processing;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>E.</p> Right to data portability;
		</div>
		<div className={style.paragraph}>
		<p className={style.paragraphMedium}>F.</p> Right to object.
		</div>
		<div className={style.paragraph}>
		This Privacy Policy does not deprive you of any other legal rights you may enforce under the applicable law.
		</div>
		<div className={style.paragraph}>
		You may be able to exercise your rights only after the Company has successfully identified you. If the Company is not sure about the identity of the person sending the request, the Company may not provide the requested information to him / her, unless his / her identity is confirmed. Therefore, if you are sending a request via e-mail, kindly ask you not only to indicate your name and surname but also to add some additional personal information that you have already shared with the Company (e.g., last four digits of your ID card number). In addition, the Company keeps the right to decide the other or additional legitimate means of identification proof that should be requested, such as a selfie with your ID document, video or voice call, or any other additional document which could let to determine your identity.
		</div>
		<div className={style.paragraph}>
		You may be provided with information related to the exercise of your rights free of charge. However, your request for the exercise of rights may be waived or may be subject to an appropriate fee if the request is manifestly unfounded or excessive, in particular because of their repetitive character.
		</div>
		<div className={style.paragraph}>
		The Company shall provide you with information on the actions taken upon receipt of your request for the exercise of your rights or the reasons for the inaction no later than within 1 month from the receipt of the request. The period for submitting the requested information may be extended, if necessary, for 2 more months, depending on the complexity and number of requests. If you have submitted the request by electronic means, the information shall also be provided by electronic means.
		</div>
		<div className={style.paragraph}>
		If you consider that your personal data is being processed in violation of your rights and legitimate interests in accordance with applicable law, you shall have the right to file a complaint against the processing of personal data to the Commissioner of Data Protection in Dubai.
		</div>

		<div className={style.littleHeader}>
		11. Final provisions
		</div>
		<div className={style.paragraph}>
		If you have any questions regarding this Privacy Policy or if you want to execute your rights you may contact us via e-mail: <a href="mailto:hello@offshorelab.com">hello@offshorelab.com</a>.
		</div>
		<div className={style.paragraph}>
		This Privacy Policy shall be viewed and applied in accordance with the applicable laws.
		</div>
		<div className={style.paragraph}>
		The Company reserves the right to revise this Privacy Policy from time to time in order to correspond with the changes of its business practice or applied legal requirements. The revised Privacy Policy will be effective upon the posting of it on the Website and you have a sole responsibility to review it. Your continued use of the Website and Services following any such revisions to the Privacy Policy will constitute your acceptance of such changes.
		</div>
	</>

	return (
		<>
			<MetaTags metaData={metaData}/>
			<ContentTemplate leftSideContent={<h1 className={style.header}>Privacy Policy</h1>} rightSideContent={content}/>
		</>
	);
}
export default PrivacyPolicy;
