// You can learn to use this component in docs/meta-tags.md

import React from "react";
import Helmet from "react-helmet";

const getDefault = {
    title: "Offshore Lab",
    description:
        "Helping to navigate the offshore finance world. Bank account opening, company formation, licensing, payment acquiring and individual tax solutions.",
    ogImage: "https://offshorelab.com/media/offshorelab-og.png",
};

export const getPageMetadata = (metaData) => {
    const defaultData = getDefault;
    return {
        title: metaData?.title || defaultData.title,
        description: metaData?.description || defaultData.description,
        keywords: metaData?.keywords,
        robots: metaData?.robots,
        canonicalUrl: metaData?.canonicalUrl,

        ogTitle: metaData?.ogTitle || metaData?.title || defaultData.title,
        ogDescription:
            metaData?.ogDescription ||
            metaData?.description ||
            defaultData.description,
        ogImage: metaData?.ogImage || defaultData.ogImage,
        ogUrl:
            metaData?.ogUrl || metaData?.canonicalUrl,
    };
};

const MetaTags = ({ metaData, service, blogMeta }) => {
    let meta = null;

    if (service) // Data came from service
    {
        // Automatically construct title and canonicalUrl for services
        let temporaryData = service.metaData;

        if (!temporaryData.title)
            temporaryData.title = service.altTitle ? service.altTitle + " | Offshore Lab" : service.title + " | Offshore Lab";

        meta = getPageMetadata(temporaryData);
    } else if (blogMeta) // Data came from blog
    {
        let temporaryData = blogMeta.metaTags || {};

        if (!temporaryData.title)
            temporaryData.title = blogMeta.headline + " | Offshore Lab Blog";

        if (!temporaryData.description)
            temporaryData.description = blogMeta.description;

        if (!temporaryData.ogImage)
            temporaryData.ogImage = `https://offshorebankaccounts.com/media/blog/blog${blogMeta.id}-og.jpg`;

        meta = getPageMetadata(temporaryData);
    } else { // A simple metaData is passed in
        meta = getPageMetadata(metaData);
    }

    return (
        <Helmet>
            <title>{meta.title}</title>
            <meta name="description" content={meta.description} />
            {meta.keywords && <meta name="keywords" content={meta.keywords} />}
            {meta.robots && <meta name="robots" content={meta.robots} />}
            {meta.canonicalUrl && <link rel="canonical" href={meta.canonicalUrl} />}

            <meta property="og:title" content={meta.ogTitle} />
            <meta property="og:description" content={meta.ogDescription} />
            <meta property="og:image" content={meta.ogImage} />
            {meta.ogUrl && <meta property="og:url" content={meta.ogUrl} />}
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
    );
};

export default MetaTags;
