import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Dominica({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			When it involves a breathtaking scenery and widespread natural beauty, then the Commonwealth of Dominica is the most intriguing island within the Caribbean. From its pristine sandy beaches to its rich pasture of tropical rainforests, this island has become a favorite spot for hiking, diving, and all relaxation zone within the Caribbean.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
				<ul className={style.highlightedList}>
				<li>-	Visa-free travel to at least one hundred and ten countries</li>
				<li>-	No taxes for non-residents</li>
				<li>-	No obligatory demand for physical residency</li>
				<li>-	Cost starts at <b>100,000 USD</b></li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Dominica Economic Citizenship Program
			</h2>
			<p className={style.paragraph}>
				If you’re searching for an area with all the attainable attraction for a vacation, wedding, a new location, or just a break from your usual life schedule, then Dominica is that the best option for you. With its good mixture of diversity and an exquisite mix of English, French, and African cultures, it boasts of the foremost serene and peaceful state across the region
			</p>
			<p className={style.paragraph}>
				What’s more? Its Economic Citizenship Program offers a good vary of supplemental edges and warranted privileges for its candidates. A number of the advantages you’re receptive to as a candidate includes;
			</p>
			<ul className={style.list}>
				<li>-	Child dependency for up to twenty-eight years old</li>
				<li>-	Full support and resident care by the principal applicant of single/unmarried daughters of at most twenty-eight years</li>
				<li>-	Aged citizen support from over fifty-five years old</li>
				<li>-	No education level barrier</li>
			</ul>

			<h2 className={style.littleHeader}>Citizenship Requirements</h2>
			<p className={style.paragraph}>
				Qualifications for the Dominica Economic Citizenship Program is favorable to anyone and everybody.
			</p>
			<p className={style.paragraph}>
				Along with an investment possibility, the subsequent general criteria are;
			</p>
			<ul className={style.list}>
				<li>- An outstanding character devoid of social vices and criminal records</li>
        		<li>- Strong health (mentally, physically, and emotionally)</li>
        		<li>- Basic English communication skills</li>
			</ul>

			<h2 className={style.littleHeader}>Investment Options</h2>
			<p className={style.paragraph}>
				Depending on your family situation, you can choose the best investment option for Dominica Citizenship Program:
			</p>

			<p className={style.paragraphHeader}>
				Single candidate
			</p>
			<p className={style.paragraph}>
				100,000 USD donation to government fund is required in case of a single candidate.
			</p>

			<p className={style.paragraphHeader}>
				Family Application (including partner)
			</p>
			<p className={style.paragraph}>
				175,000 USD donation to government fund is required in case of a main candidate and his/her partner.
			</p>

			<p className={style.paragraphHeader}>
				Family Application II (including 2 kids under age 18)
			</p>
			<p className={style.paragraph}>
				175,000 USD donation to government fund is required in case of a main candidate and two kids each under the 18 years old.
			</p>

			<p className={style.paragraphHeader}>
				Family Application III (standard family size: partner and 2 kids under age 18)
			</p>
			<p className={style.paragraph}>
				200,000 USD donation to government fund is required in case of a main candidate, his/her partner and two kids each under the 18 years old.
			</p>
			<p className={style.paragraph}>
				<i>An extra dependent is to be supplemental, an additional donation of 50,000 USD is required.</i>
			</p>
			
			<p className={style.paragraphHeader}>
				Investment in real estate
			</p>
			<p className={style.paragraph}>
				All candidates following this path are required to purchase property worth at least 200,000 USD in any government-approved realty development. This property is to be maintained for a minimum of three years. Provided it is maintained and sold after five years, a consecutive customer would be enabled thought for the citizenship program.
			</p>
		</div>
	)
}

export default Dominica;
