import React from 'react'
import style from './serviceTemplate.module.scss'

import MetaTags from 'components/MetaTags'
import StillDeciding from 'components/services/StillDeciding'
import HeaderTemplate from 'components/templates/HeaderTemplate'
import ContentTemplate from 'components/templates/ContentTemplate'

function ServiceTemplate({data, subData, headerRightContent, leftContent, rightContent})
{
    const headerLeftContent=
    <div>
        <h1 className={style.header}>{subData?.altTitle ? subData.altTitle : (subData?.title ? subData.title : data.title)} <div className={style.logo}>{data.image}</div></h1>
    </div>

    return(
        <div>
            <MetaTags service={subData? subData : data}/>
            <HeaderTemplate leftSideContent={headerLeftContent} rightSideContent={headerRightContent}/>
            <ContentTemplate leftSideContent={leftContent} rightSideContent={rightContent}/>
            <StillDeciding />
        </div>
    )
}

export default ServiceTemplate