import { isDomain, isURL } from 'helpers/validation';
import React from 'react';
import { Link } from 'react-router-dom';

const BlogLink = ({ href, children, className }) => {
  // Determines if the link is internal or external
  if (isDomain(href) || !isURL(href)) {
    return (
      <Link className={className} to={href.replace(/^.*\/\/[^\/]+/, '')}>
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }
};

export default BlogLink;
