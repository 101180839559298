import React from 'react'
import style from './faqInsideCategory.module.scss'
import slugify from 'helpers/Slugify.js'

import MetaTags from 'components/MetaTags'
import FaqInsideTemplate from './FaqInsideTemplate'
import { Link } from 'react-router-dom'

function FaqInsideCategory({category, routeList})
{
    const metaData={
        title: category.title + ' | Offshore Bank Accounts FAQ'
    }

    const popularQuestionCount = 4;
    const leftSideTitle = "Popular questions"
    const leftSideContent = 
    category.questions.slice(0, popularQuestionCount).map((question, key)=>
    <Link to={'/faq/' + slugify(category.title) + '/' + slugify(question.title)} className={style.question} key={key}>
        {question.title}
    </Link>)

    const rightSideContent =
    <div>
        <div className={style.topicHeader}>Questions</div>
        {
            category.questions.map((question, key)=>
            <Link to={'/faq/' + slugify(category.title) + '/' + slugify(question.title)} className={style.question} key={key}>
                {question.title}
            </Link>)
        }
    </div>

    return(
    <>
        <MetaTags metaData={metaData}/>
        <FaqInsideTemplate leftSideTitle={leftSideTitle} leftSideContent={leftSideContent} rightSideContent={rightSideContent} routeList={routeList}/>
    </>
    )
}

export default FaqInsideCategory