import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Vanuatu({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Introduced in 2017, Vanuatu Development Support Program is aiming to support infrastructure and promote economic development of Vanuatu. To raise funds Vanuatu Government required candidates willing to get Vanuatu Citizenship to donate the Development Support Program.
		</p>
		<p className={style.paragraph}>
			Vanuatu citizenship by investment program is unique as it is based in Asia-Pacific region, while most of other programs are offered Eastern Carribean Sates and the country itself has relatively large population of over 300,000 residents.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>-	Citizenship in 6-8 weeks</li>
				<li>-	No residency requirements</li>
				<li>-	Visa free travel to more than 129 countries including United Kingdom, Schengen area (European Union), Singapore and Hong Kong</li>
				<li>-	No taxes on worldwide income or wealth</li>
				<li>-	Cost starts at <b>135,700 USD</b></li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Vanuatu	Development Support Program
			</h2>
			<p className={style.paragraph}>
				This program is one of the fastest citizenship by investment programs globally and the only one in the South Pacific region.
			</p>
			<ul className={style.list}>
				<li>-	Timely and straightforward passport processing that takes up to two months.</li>
				<li>-	Citizenship can be passed to future generations by descent.</li>
				<li>-	No obligation to visit Vanuatu for the application purpose.</li>
				<li>-	Eligibility to apply for a Vanuatu driving license, National ID and Vanuatu birth certificate.</li>
			</ul>

			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<ul className={style.list}>
				<li>- Have a crime-free criminal record. </li>
				<li>- Notarised passport copy.</li>
				<li>- Proof of assets worth atleast 250,000 USD in applicant's name.</li>
				<li>- No language requirements.</li>
			</ul>

			<h2 className={style.littleHeader}>
				Investment Options
			</h2>
			<p className={style.paragraph}>
				Vanuatu citizenship by investment process is very transparent and straightforward with only an option of donation to the Development Support Program.
			</p>

			<p className={style.paragraphHeader}>
				Donation to Development Support Program
			</p>
			<p className={style.paragraph}>
				The following are the contribution levels that need to be met based on the number of applicants.
			</p>
			<ul className={style.list}>
				<li>-	130,000 USD for the main applicant;</li>
				<li>-	150,000 USD for a married couple (applicant and his/her spouse)</li>
				<li>-	165,000 USD for a married couple (applicant and his/her spouse) with one kid under age 18</li>
				<li>-	180,000 USD for a married couple (applicant and his/her spouse) with two kids under age 18</li>
				<li>-	10,000 USD for each additional dependent (kids, parents, grandparents) under age 25 or above age 50</li>
			</ul>

			<p className={style.paragraph}>
				In addition there are some other fees
			</p>
			<ul className={style.list}>
				<li>-	5,500 USD for due dilligence per applcation (non-refundable)</li>
				<li>-	50 USD as application fee</li>
				<li>-	100 USD as certification fee</li>
				<li>-	250 USD for a passport per person</li>
				<li>-	300 USD for the commissioner of oath</li>
			</ul>
		</div>
	)
}

export default Vanuatu;
