import React, { useState } from 'react'
import style from './headerMobile.module.scss'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'assets/images/vector/obclogo.svg'
import { ReactComponent as Burger } from 'assets/images/vector/menu.svg'

import SocialMediaLinks from 'components/navigation/socialMediaLinks/SocialMediaLinks'
import NavigationMenu from './NavigationMenu'

function HeaderMobile() {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <div>
            <div className={style.container}>
                <Link to="/">
                    <Logo className={style.logo} />
                </Link>
                <div className={style.buttons}>
                    <div className={style.hideOnMobile}>
                        <SocialMediaLinks />
                    </div>

                    <span onClick={() => {
                        setShowMenu(true)
                    }} className={style.open}>
                        <Burger />
                    </span>
                </div>
            </div>

            <NavigationMenu setShowMenu={setShowMenu} showMenu={showMenu} />
        </div>
    )
}

export default HeaderMobile