import React from 'react';
import { Link } from 'react-router-dom';
import style from './blogLinks.module.scss';

function BlogLinks({page}) {

	let to={
		pathname: "/blog/",
		page: page
	}

	return (
		<div>
			<Link to={to} className={style.link}>
				<div className={style.backButton}>Back to Blog</div>
			</Link>
		</div>
	);
}


export default BlogLinks;
