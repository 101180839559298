import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Turkey({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Turkey ranks among the most developed Muslim countries, providing an ideal business environment and a safe environment for families across all religions. Besides, Turkey is a valid candidate for the European Union, a member of G20 and NATO, and its strategic position between Asia, Europe, and the Middle East makes it an ideal destination for investors.
		</p>
		<p className={style.paragraph}>
			Unveiled in 2016, Turkey’s Citizenship by Investment Programme has been amended to enhance leniency, encourage foreign investment, and attract more settlers.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Key benefits
			</h2>
			<ul className={style.highlightedList}>
				<li>- Residency within three weeks</li>
				<li>- Citizenship approval within six months</li>
				<li>- Visa free travel to over 125 countries.</li>
				<li>- Dual citizenship is permitted in Turkey.</li>
				<li>- No residency requirements</li>
				<li>- No donation required</li>
				<li>- Investment in real estate from <b>250,000 USD</b> required</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Citizenship by Investment Program
			</h2>
			<p className={style.paragraph}>
				Previously it required an investment of at least 1,000,000 USD in real estate, however, the limit was reduced to 250,000 USD in 2018, which makes in very attractive option.
			</p>
			<p className={style.paragraph}>
				Upon becoming a Turkish citizen, you will enjoy top and free medical care, and your children will be enrolled in the top and free education systems in the country.
			</p>
				
			<h2 className={style.littleHeader}>
				Citizenship Requirements
			</h2>
			<p className={style.paragraph}>
				Turkey offers investors a fast route to obtain residency or a second passport through several investments options upon meeting the following requirements.
			</p>
			<ul className={style.list}>
				<li>- Possess a valid passport or travel document.</li>
				<li>- Have a clean criminal record. </li>
				<li>- Present all the required documents and pass all the background checks and verifications concerning the source of your income and funds for investment. </li>
			</ul>

			<h2 className={style.littleHeader}>
				Investment Options
			</h2>
			<p className={style.paragraph}>
				Applicants are required to invest in immovable property worth 250,000 USD or an equivalent amount in Euros or Turkish Lira. This investment has to be maintained for three years by the applicant, but it can be resold after the time has elapsed.
			</p>
			<p className={style.paragraph}>
				With a rich culture and stunning landscape, Turkey attracts millions of tourists and investors every year. The country is rapidly becoming a global hub of business, innovation, and the residential center of power.
			</p>
			<p className={style.paragraph}>
				The country has great fundamentals that investors can capitalize on, including its emerging market status, young population, and strategic position.
			</p>
		</div>
	)
}

export default Turkey;