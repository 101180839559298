import image1 from "assets/images/raster/authors/author1.jpg";
import image2 from "assets/images/raster/authors/author2.jpeg";

export default {
    gediminas: {
        name: "Gediminas Butkus",
        bio: "Gediminas Butkus is Banking, Payments, and Tax Advisor, opens the doors in the banking industry. Primarily focused on helping people navigate the complex offshore world, especially helping entrepreneurs, digital and offshore businesses to get bank accounts.",
        image: image1,
        socials: {
            linkedin: "https://www.linkedin.com/in/gedasbu/",
        },
        routePath: "gediminas-butkus"
    },
    luigi: {
        name: "Luigi Wewege",
        bio: "Luigi Wewege is the Senior Vice President and Head of Private Banking of Belize based Caye International Bank, a FinTech School Instructor, and the published author of The Digital Banking Revolution – now in its third edition.",
        image: image2,
        socials: {
            linkedin: "https://www.linkedin.com/in/luigiwewege/",
            twitter: "https://twitter.com/luigiwewege",
        },
        routePath: "luigi-wewege"
    }
}