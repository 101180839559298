import React from 'react';
import style from '../serviceComponent.module.scss'
import ServiceTemplate from 'components/services/serviceTemplate/ServiceTemplate'

function Bulgaria({data, subData}) {
	const headerRightContent=
	<div>
		<p className={style.paragraph}>
			Bulgaria with its diversity and rich cultural heritage offers not only an ideal place for working, but also the perfect gateway into exploring the European continent which boasts of a well organized economy, lovely people and envious culture.
		</p>
	</div>

	return <ServiceTemplate data={data} subData={subData} headerRightContent={headerRightContent} leftContent={<LeftContent />} rightContent={<RightContent />}/>
}

function LeftContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>Key benefits</h2>

			<p className={style.paragraphHeader}>
				For permanent residents:
			</p>
			<ul className={style.highlightedList}>
				<li>•	Pending Bulgaria becoming a member of the EU, investors will enjoy free movement within EU countries.</li>
				<li>•	Access to the best health care in Bulgaria and within the EU;</li>
				<li>•	Tax Exceptions on World Income dependent on certain conditions. </li>
			</ul>

			<p className={style.paragraphHeader}>
				For citizens:
			</p>
			<ul className={style.highlightedList}>
				<li>•	Candidates can live, work or school in any part of Europe unconditionally;</li>
				<li>•	 Right to be a landlord in any EU country.</li>
			</ul>
		</div>
	)
}

function RightContent(){
	return(
		<div>
			<h2 className={style.littleHeader}>
				Investor Program for Residence and Citizenship
			</h2>
			<p className={style.paragraph}>
				Being one of the EU’s youngest member states, Bulgaria’s perfect location on the South-eastern fringe of Europe coupled with its temperate climate and rich cultural diversity makes it a top notch location ideal for settling and investing, and the getting there is now made faster by the Investor Program for Residency and Citizenship.
			</p>
			<p className={style.paragraph}>
				Program's Advantages:
			</p>
			<ul className={style.list}>
				<li>•	Quick processing to residency (between 6-9 months);</li>
				<li>•	Accelerated approval of citizenship within 2 years;</li>
				<li>•	Exclusive immunity from physical residence backed by legislation;</li>
				<li>•	Assured investment and available financing preferences;</li>
				<li>•	Language requirements for residence or citizenship not necessary;</li>
				<li>•	Under no obligation to forgo current citizenship.</li>
				<li>•	Tax friendly and free-trade environment within the EU.</li>
			</ul>

			<h2 className={style.littleHeader}>Citizenship Requirements</h2>
			<p className={style.paragraph}>
				Getting an accredited citizenship or residency in Bulgaria is quite easy and has been made faster, via the Investor Program for Residence and Citizenship in line with the Foreigners Act of the Republic of Bulgaria’s. Eligible candidates to be considered for this program must fulfill the following:
			</p>
			<ul className={style.list}>
				<li>•	Must be a national of a non-Schengen country;</li>
				<li>•	Have a valid passport or credible alternative;</li>
				<li>•	Possess a foreign travel document;</li>
				<li>•	If applying for the financed investment option, must Have a net worth of €1 million and above, but a net worth requirement of €511,292 for the full investment option;</li>
				<li>•	Must make available records backing the source and origin of the declared and invested funds;</li>
				<li>•	Have zero criminal record;</li>
				<li>•	Must certify the Power of Attorneys and the Investment Agreement;</li>
				<li>•	Pay costs for application;</li>
				<li>•	Make the minimum specified legal trips to Bulgaria.</li>
			</ul>
			<p className={style.paragraph}>
				Foreign investors who hold permanent residence permits for a minimum of 5years, are by the Bulgarian Citizenship Act, qualified to apply for Bulgarian citizenship. But for those who prefer the formal citizenship program, they must meet the following requirements:</p>
			<ul className={style.list}>
				<li>•	Must be at least 18years of age.</li>
				<li>•	Posses a valid residential address for the past five years and must have maintained permanent resident status. </li>
				<li>•	Never been convicted for a capital offence by a Bulgarian court or engaged in any criminal proceedings having being accused of such a crime (except he/she has been reformed)</li>
				<li>•	Candidates must need to file a citizenship request and show a clean bill of health from their current country of residence.</li>
			</ul>

			<h2 className={style.littleHeader}>
				Investment Options
			
			</h2>
			<p className={style.paragraphHeader}>
				Full Investment
			</p>
			<p className={style.paragraph}>
				This investment option, completely guaranteed by the government bonds, allows the investor to make a mandatory payment of BGN1 million (€511,292) which will be invested over a period of 5years, after which the original amount would be returned to the investor with no accumulated interests.
			</p>

			<p className={style.paragraphHeader}>
				Financed Investment
			</p>
			<p className={style.paragraph}>
				The financed investment package comes at a pre-paid cost to potential clients seeking to influence their capitals. Applicants for this package are first expected to provide proof of worldwide assets estimated at €1million which is a prerequisite of the Bulgarian Chartered Bank, after which they invest a lesser amount which is used to finance a BGN1million (€511,292) closed 5.5 year credit. The credit is initiated and repaid at no extra costs to the investor.
			</p>

			<p className={style.paragraphHeader}>
				Fast-track citizenship option
			</p>
			<p className={style.paragraph}>
				By doubling their investments, this package as the name implies, permits investors apply faster for citizenship or residency, having met the below criteria:
			</p>

			<ul className={style.list}>
				<li>•	Must have a minimum of 1 permanent residency status;</li>
				<li>•	Double the investment by paying €512,000 in government bonds or in the capital of a Bulgarian company executing a Priority Investment Project (as outlined in the Investment Promotion Act);</li>
				<li>•	Keep all additional investment for a 2year period.</li>
			</ul>

			<p className={style.paragraph}>
				Applications for participants in the fast-track citizenship program are reviewed under swifter processes, and eligible candidates are open to choose any of the options.
			</p>
		</div>
	)
}

export default Bulgaria;
