import React from 'react'
import style from './faqNotFound.module.scss'

import ContentTemplate from 'components/templates/ContentTemplate'
import { ReactComponent as Island } from 'assets/images/vector/not-found-island.svg'

function FaqNotFound() {
    const rightContent =
        <div className={style.textContainer}>
            <h3 className={style.littleHeader}>
                Could not find what you were looking for?
            </h3>
            <span className={style.text}>
                Get in touch with us via <a className={style.email} href="mailto:hello@offshorebankaccounts.com">hello@offshorebankaccounts.com</a> or through our Live chat. Our team of live support agents will be glad to help you.
            </span>
        </div>

    return <div className={style.container}>
        <ContentTemplate leftSideContent={<Island className={style.image} />} rightSideContent={rightContent} />
    </div>
}

export default FaqNotFound